import styled, { css } from 'styled-components';

import Paragraph from 'components/_shared/Paragraph/Paragraph';
import Heading from 'components/_shared/Heading/Heading';
import { colors, media } from 'utils/variables';
import { fadeIn } from 'utils/keyframes';

const dynamicAnimationStyle = ({ $inView }) =>
  $inView
  && css`
    animation: ${fadeIn} 0.7s forwards;
  `;

export const StyledSection = styled.div`
  margin-bottom: 200px;

  @media (min-width: ${media.l}) {
    position: relative;

    :after {
      content: '';
      width: 200px;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 4;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
`;

export const StyledHeading = styled(Heading)`
  text-align: center;

  @media (min-width: ${media.s}) {
    text-align: left;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.grayLight};
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: ${media.s}) {
    text-align: left;
    padding-left: 30px;
  }
`;

export const StyledMainCard = styled.div`
  margin-bottom: 75px;
  opacity: 0;

  ${dynamicAnimationStyle};
`;

export const StyledWrapper = styled.div`
  opacity: 0;

  ${dynamicAnimationStyle};
`;
