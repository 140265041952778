import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';

import {
  StyledSection,
  StyledContent,
  StyledImage,
  StyledHeading,
  StyledPrimaryParagraph,
  StyledSecondaryParagraph,
  StyledPhoneContact,
  StyledButton,
  StyledWrapper,
  StyledTexts,
} from './styles';

import Container from 'components/_shared/Container';
import { imageProp, buttonProp, phoneContactProp } from 'utils/types';

const Hero = ({ sectionData }) => {
  const { title, subtitle, description, mainImage, button, phoneContact }
    = sectionData;
  const { color, label, anchorLink } = button.document.data;
  const { phoneNumber, phoneLabel } = phoneContact.document.data;

  const [Icon, setIcon] = useState(null);
  const image = getImage(mainImage.localFile);

  useEffect(() => {
    import('assets/icons/dna.inline.svg').then(res =>
      setIcon(() => res.default),
    );
  }, []);

  return (
    <StyledSection>
      <Container>
        <StyledContent>
          <StyledTexts>
            <StyledHeading type="h1">{title}</StyledHeading>
            <StyledPrimaryParagraph>{subtitle}</StyledPrimaryParagraph>
            <StyledSecondaryParagraph>{description}</StyledSecondaryParagraph>
          </StyledTexts>
          <StyledButton path={anchorLink} color={color}>
            {label}
          </StyledButton>
          <StyledPhoneContact link={phoneNumber} label={phoneLabel} />
        </StyledContent>
      </Container>
      <StyledImage image={image} alt="Biotech Company focused on preclinical drug development." />
      {Icon && (
        <StyledWrapper>
          <Suspense fallback={<></>}>
            <Icon />
          </Suspense>
        </StyledWrapper>
      )}
    </StyledSection>
  );
};

Hero.propTypes = {
  sectionData: PropTypes.shape({
    button: buttonProp.isRequired,
    phoneContact: phoneContactProp.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    mainImage: imageProp.isRequired,
  }),
};

export default Hero;
