import { useEffect, useState, useRef } from 'react';
import Glide, {
  Controls,
  Breakpoints,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';

const defaultConfig = {
  perView: 1,
  type: 'slider',
  startAt: 0,
};

const checkIsFirst = current => current === 0;
const checkIsLast = (current, last) => current + 1 === last;

const useSlider = ({
  className = '.glide',
  config = defaultConfig,
  maxSlide,
}) => {
  const [currentSlide, setCurrentSlide] = useState(config.startAt);
  const [isFirst, setIsFirst] = useState(() => checkIsFirst(currentSlide));
  const [isLast, setIsLast] = useState(() =>
    checkIsLast(currentSlide, maxSlide),
  );

  const ref = useRef(0);

  useEffect(() => {
    const glide = new Glide(className, config);

    glide.on('run', ({ steps, direction }) => {
      let currentStep;

      if (direction !== '=') {
        const isNextSwip = direction === '>';
        const isPrevSwip = direction === '<';
        const { current } = ref;

        const isCurrentFirst = checkIsFirst(current);
        const isCurrentLast = checkIsLast(current, maxSlide);

        if (isCurrentFirst) {
          currentStep = isNextSwip ? current + 1 : 0;
        } else if (isCurrentLast) {
          currentStep = isPrevSwip ? current - 1 : current;
        } else {
          currentStep = isNextSwip ? current + 1 : current - 1;
        }
      } else {
        currentStep = Number(steps);
      }

      setCurrentSlide(currentStep);
      ref.current = currentStep;

      const isStepFirst = checkIsFirst(ref.current);
      const isStepLast = checkIsLast(ref.current, maxSlide);

      setIsFirst(isStepFirst);
      setIsLast(isStepLast);
    });

    glide.mount({ Controls, Breakpoints, Swipe });
  }, []);

  return { currentSlide, isFirst, isLast };
};

export default useSlider;
