import styled, { css } from 'styled-components';

import { media, colors } from 'utils/variables';
import Arrow from 'assets/icons/arrow.inline.svg';

const dynamicButtonStyles = ({ $rotate }) =>
  $rotate
  && css`
    transform: rotate(180deg) translateY(4px);
    margin-right: 20px;
  `;

const dynamicDisabledButtonStyles = ({ disabled }) =>
  disabled
  && css`
    opacity: 0.3;
    cursor: auto;
  `;

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledGlide = styled.div`
  width: 100%;
  position: static !important;

  @media (min-width: ${media.l}) {
    width: 100vw !important;
  }
`;

export const StyledTracker = styled.div`
  max-width: 280px;
  margin: 0 auto;

  @media (min-width: ${media.s}) {
    max-width: none;
    width: 350px;
  }

  @media (min-width: ${media.m}) {
    max-width: none;
    width: 650px;
  }

  @media (min-width: ${media.l}) {
    width: 100vw !important;
  }
`;

export const StyledSlides = styled.div`
  width: 100%;
  padding: 0;
`;

export const StyledControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  position: absolute;
  width: 100%;

  @media (min-width: ${media.l}) {
    justify-content: space-between;
  }
`;

export const StyledBullets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .glide__bullet--active {
    background-color: ${colors.black};
  }

  @media (min-width: ${media.l}) {
    justify-content: flex-start;
  }
`;

export const StyledBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${colors.secondary3};
  cursor: pointer;
  margin-right: 15px;

  :last-child {
    margin-right: 0;
  }
`;

export const StyledArrows = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: ${media.l}) {
    margin: 0;
    width: auto;
    justify-content: flex-end;
  }
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${dynamicButtonStyles};
  ${dynamicDisabledButtonStyles};
`;

export const StyledArrow = styled(Arrow)`
  path {
    stroke: ${colors.secondary};
    transition: stroke 0.2s;
  }

  :hover,
  :focus {
    path {
      stroke: ${colors.black};
    }
  }
`;
