import styled, { css } from 'styled-components';

import { fontWeights, colors, media, fonts } from 'utils/variables';
import Paragraph from 'components/_shared/Paragraph/Paragraph';
import { fadeIn } from 'utils/keyframes';

const dynamicAnimationStyles = ({ $inView }) => css`
  animation: ${fadeIn} 1s forwards ${$inView ? 'running' : 'paused'};
`;

export const StyledContainer = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: center;

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledText = styled(Paragraph)`
  width: 50%;
  margin: 0;
  color: ${colors.grayLight};
  font-size: 0.8125rem;
  font-family: ${fonts.montserrat};
  font-weight: ${fontWeights.light};

  ${dynamicAnimationStyles};

  @media (min-width: ${media.s}) {
    width: 60%;
  }

  @media (min-width: ${media.m}) {
    width: 100%;
  }
`;

export const StyledIcon = styled.div`
  svg {
    width: 100px;
    height: 100px;
  }
`;
