import styled from 'styled-components';

import Paragraph from 'components/_shared/Paragraph/Paragraph';
import { colors } from 'utils/variables';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  :last-child {
    margin: 0;
  }
`;

export const StyledTitle = styled(Paragraph)`
  margin-left: 10px;
  text-decoration: none;
  word-break: break-all;
  color: ${colors.greenDark};
`;
