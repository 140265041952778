import styled, { css, keyframes } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Button from 'components/_shared/Button/Button';
import Heading from 'components/_shared/Heading/Heading';
import Paragraph from 'components/_shared/Paragraph/Paragraph';
import PhoneContact from 'components/_shared/PhoneContact/PhoneContact';
import { colors, media } from 'utils/variables';

const rotateLargeFrom = `scale(0.9) rotate3d(0, 1, 0, 180deg)`;
const rotateLargeTo = `scale(0.9) rotate3d(0, 1, 0, 0)`;
const rotateMediumFrom = `scale(0.7) rotate3d(0, 1, 0, 180deg)`;
const rotateMediumTo = `scale(0.7) rotate3d(0, 1, 0, 0)`;
const rotateSmallFrom = `scale(0.7) rotate(90deg) rotate3d(0, 1, 0, 180deg)`;
const rotateSmallTo = `scale(0.7) rotate(90deg) rotate3d(0, 1, 0, 0)`;

const rotateLarge = keyframes`
  from {
    transform: ${rotateLargeFrom};
    opacity: 0;
  }
  to {
    transform: ${rotateLargeTo};
    opacity: 1;
  }
`;

const rotateMedium = keyframes`
  from {
    transform: ${rotateMediumFrom};
    opacity: 0;
  }
  to {
    transform: ${rotateMediumTo};
    opacity: 1;
  }
`;

const rotateSmall = keyframes`
  from {
    transform: ${rotateSmallFrom};
    opacity: 0;
  }
  to {
    transform: ${rotateSmallTo};
    opacity: 1;
  }
`;

const sharedStyles = css`
  @media (min-width: ${media.s}) {
    text-align: left;
  }
`;

export const StyledSection = styled.div`
  margin-bottom: 200px;
  position: relative;

  @media (min-width: ${media.s}) {
    background-color: ${colors.creamy};
  }

  @media (min-width: ${media.m}) {
    height: 100vh;
    min-height: 700px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 600px;
  margin-top: 100px;

  @media (min-width: ${media.s}) {
    width: 55%;
    min-height: 800px;
    align-content: center;
  }

  @media (min-width: ${media.m}) {
    width: 50%;
    height: 110vh;
    min-height: 900px;
    padding-right: 30px;
    margin-top: 0;
  }
`;

export const StyledImage = styled(GatsbyImage)`
  width: 100%;

  @media (min-width: ${media.s}) {
    width: 40%;
    min-height: 800px;
    position: absolute !important;
    right: 0;
    top: 0;
  }

  @media (min-width: ${media.m}) {
    width: 50%;
    min-height: 700px;
    height: 100vh;
    top: 0;
  }
`;

export const StyledTexts = styled.div`
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  font-size: 2.5rem;
  text-align: center;
  width: 100%;

  ${sharedStyles};

  @media (min-width: ${media.s}) {
    font-size: 3.125rem;
  }
`;

export const StyledPrimaryParagraph = styled(Paragraph)`
  color: ${colors.secondary};
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;

  ${sharedStyles};
`;

export const StyledSecondaryParagraph = styled(Paragraph)`
  color: ${colors.grayLight};
  text-align: center;
  width: 100%;

  ${sharedStyles};

  @media (min-width: ${media.m}) {
    width: 320px;
  }
`;

export const StyledButton = styled(Button)`
  margin: 50px auto;

  @media (min-width: ${media.s}) {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StyledWrapper = styled.div`
  position: absolute;
  top: 600px;
  left: 50%;
  animation-name: ${rotateSmall};
  animation-duration: 1s;
  animation-easing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin-left: -55px;
  margin-top: -188px;

  svg {
    transform: translateX(-2px);
  }

  @media (min-width: ${media.s}) {
    animation-name: ${rotateMedium};
    left: 60%;
    top: 50%;
    margin-left: -55px;
    margin-top: -132px;
  }

  @media (min-width: ${media.m}) {
    left: 50%;
    margin-left: -55px;
    margin-top: -170px;
  }

  @media (min-width: ${media.l}) {
    animation-name: ${rotateLarge};
  }
`;

export const StyledPhoneContact = styled(PhoneContact)`
  width: 100%;
  justify-content: center;

  @media (min-width: ${media.s}) {
    justify-content: flex-start;
    position: absolute;
    bottom: 44px;
    width: auto;
  }
`;
