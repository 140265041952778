import PropTypes from 'prop-types';
import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

import {
  StyledParagraph,
  StyledColumn,
  StyledImage,
  StyledList,
  StyledSection,
  StyledContent,
  StyledButton,
  StyledHeading,
} from './styles';
import Item from './Item';

import Container from 'components/_shared/Container';
import RichText from 'components/_shared/RichText';
import { buttonProp, richTextProp, imageProp } from 'utils/types';

const inViewConfig = {
  threshold: 0.2,
  triggerOnce: true,
};

const AboutUs = ({
  sectionData: {
    title,
    description,
    button,
    aboutUsImage,
    listItems,
    sectionId,
  },
}) => {
  const { color, label, anchorLink } = button.document.data;
  const image = getImage(aboutUsImage.localFile);

  const [primaryRef, primaryInView] = useInView(inViewConfig);
  const [secondaryRef, secondaryInView] = useInView(inViewConfig);

  const renderItems = ({ item, icon }) => (
    <Item key={item} icon={icon} inView={secondaryInView} text={item} />
  );

  return (
    <StyledSection id={sectionId}>
      <Container>
        <StyledContent>
          <StyledColumn ref={primaryRef}>
            <StyledHeading $inView={primaryInView} type="h2">
              {title}
            </StyledHeading>
            <RichText
              text={description.richText}
              components={{
                paragraph: ({ children }) => (
                  <StyledParagraph $inView={primaryInView}>
                    {children}
                  </StyledParagraph>
                ),
              }}
            />
            <StyledButton path={anchorLink} color={color}>
              {label}
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledImage image={image} alt={aboutUsImage.alt} />
          </StyledColumn>
          <StyledColumn>
            <StyledList ref={secondaryRef}>
              {listItems.map(renderItems)}
            </StyledList>
          </StyledColumn>
        </StyledContent>
      </Container>
    </StyledSection>
  );
};

AboutUs.propTypes = {
  sectionData: PropTypes.shape({
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.string.isRequired,
      }),
    ).isRequired,
    description: PropTypes.shape({
      richText: richTextProp.isRequired,
    }),
    aboutUsImage: imageProp.isRequired,
    button: buttonProp.isRequired,
    sectionId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default AboutUs;
