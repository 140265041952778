import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/common/Layout';
import Hero from 'components/Home/Hero';
import AboutUs from 'components/Home/AboutUs';
import OurHistory from 'components/Home/OurHistory';
import OurTeam from 'components/Home/OurTeam';
import Contact from 'components/Home/Contact';
import useComponents from 'utils/useComponents';
import Seo from 'components/common/SEO';

const elements = {
  hero: Hero,
  aboutUs: AboutUs,
  ourHistory: OurHistory,
  team: OurTeam,
  contact: Contact,
};

const PageIndex = ({ data }) => {
  const { sections } = data.homepage.data;
  const { header, headerDarkMode, footer } = data.homepage.data;
  const components = useComponents(elements, sections);

  return (
    <Layout headerDarkMode={headerDarkMode} header={header} footer={footer}>
      {components}
    </Layout>
  );
};

export const Head = ({ data, location }) => {
  const metatags = data.homepage.data.body[0].primary;
  return <Seo metatags={metatags} location={location} />;
};

PageIndex.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        sections: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        footer: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        header: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        headerDarkMode: PropTypes.bool.isRequired,
      }),
    }),
  }),
};

export const query = graphql`
  query HomepageQuery {
    homepage: prismicHomepage {
      data {
        headerDarkMode: header_dark_mode
        header {
          document {
            ...Header
          }
        }
        footer {
          document {
            ...Footer
          }
        }
        sections {
          document {
            ...HomepageSections
          }
        }
        body {
          ...SEOHome
        }
      }
    }
  }
`;

export default PageIndex;
