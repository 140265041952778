import React from 'react';

const useComponents = (copmonents, sections) =>
  Object.entries(sections.document.data).map(([key, val]) => {
    const Section = copmonents[key];

    if (typeof val === 'object' && Section) {
      return <Section key={key} sectionData={val.document.data} />;
    }
  });

export default useComponents;
