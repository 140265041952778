import styled, { css } from 'styled-components';

import { colors, media } from 'utils/variables';
import Heading from 'components/_shared/Heading/Heading';
import Paragraph from 'components/_shared/Paragraph/Paragraph';

const dynamicBoxStyles = ({ $isDarkTheme }) => css`
  background-color: ${$isDarkTheme && colors.black};
  box-shadow: ${!$isDarkTheme && '0px 0px 15px rgba(37, 143, 103, 0.1)'};

  p,
  a {
    color: ${$isDarkTheme && colors.white};
  }

  path {
    fill: ${$isDarkTheme && colors.white};
  }
`;

export const StyledContainer = styled.div`
  text-align: left;
  color: ${colors.grayLight};
  border-radius: 16px;
  width: 100%;
  padding: 25px;
  margin-bottom: 50px;
  max-width: 450px;

  ${dynamicBoxStyles};

  @media (min-width: ${media.m}) {
    max-width: 420px;
  }
`;

export const StyledTitle = styled(Heading)`
  color: ${({ $isDarkTheme }) =>
    $isDarkTheme ? colors.white : colors.greenDark};
`;

export const StyledDescription = styled(Paragraph)`
  margin-top: 60px;
  color: ${colors.grayLight};
`;
