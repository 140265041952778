import React from 'react';
import PropTypes from 'prop-types';
import '@glidejs/glide/dist/css/glide.core.min.css';

import {
  StyledContainer,
  StyledGlide,
  StyledTracker,
  StyledBullet,
  StyledBullets,
  StyledSlides,
  StyledArrow,
  StyledArrows,
  StyledControls,
  StyledButton,
} from './styles';

import { childrenProp } from 'utils/types';
import useSlider from 'utils/useSlider';

const getBullets = length => {
  const bullets = [];
  for (let i = 0; i < length; i++) {
    bullets.push(StyledBullet);
  }
  return bullets;
};

const Slider = ({ children, config, className }) => {
  const { isFirst, isLast } = useSlider({
    maxSlide: children.length,
    config,
  });

  const bullets = getBullets(children.length);

  return (
    <StyledContainer>
      <StyledGlide className={`glide ${className}`}>
        <StyledTracker className="glide__track" data-glide-el="track">
          <StyledSlides className="glide__slides">{children}</StyledSlides>
        </StyledTracker>
        <StyledControls>
          <StyledBullets
            className="glide__bullets"
            data-glide-el="controls[nav]"
          >
            {bullets.map((Bullet, index) => (
              <Bullet
                key={index}
                className="glide__bullet"
                data-glide-dir={`=${index}`}
              />
            ))}
          </StyledBullets>
          <StyledArrows className="glide__arrows" data-glide-el="controls">
            <StyledButton
              aria-label="Prev slide"
              className="glide__arrow glide__arrow--left"
              data-glide-dir="<"
              disabled={isFirst}
              $rotate
            >
              <StyledArrow />
            </StyledButton>
            <StyledButton
              aria-label="Next slide"
              className="glide__arrow glide__arrow--right"
              data-glide-dir=">"
              disabled={isLast}
            >
              <StyledArrow />
            </StyledButton>
          </StyledArrows>
        </StyledControls>
      </StyledGlide>
    </StyledContainer>
  );
};

Slider.propTypes = {
  children: childrenProp.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(['carousel', 'slider']),
    perView: PropTypes.number,
    gap: PropTypes.number,
    startAt: PropTypes.number,
    animationDuration: PropTypes.number,
    dragTreshold: PropTypes.bool,
  }),
  className: PropTypes.string,
};

export default Slider;
