import React from 'react';
import PropTypes from 'prop-types';

import { StyledContainer, StyledIcon, StyledText } from './styles';

const Item = ({ text, icon, inView }) => {
  const itemIcon = icon.localFile.svg.content;

  return (
    <StyledContainer key={text}>
      <StyledIcon dangerouslySetInnerHTML={{ __html: itemIcon }} />
      <StyledText $inView={inView} $isSmall>
        {text}
      </StyledText>
    </StyledContainer>
  );
};

Item.propTypes = {
  icon: PropTypes.shape({
    localFile: PropTypes.shape({
      svg: PropTypes.shape({
        content: PropTypes.string,
      }),
    }),
  }),
  inView: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default Item;
