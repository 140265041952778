import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { fontWeights, colors, media } from 'utils/variables';
import Paragraph from 'components/_shared/Paragraph/Paragraph';
import Button from 'components/_shared/Button/Button';
import Heading from 'components/_shared/Heading/Heading';
import { fadeIn } from 'utils/keyframes';

const dynamicAnimationStyles = ({ $inView }) => css`
  animation: ${fadeIn} 1s forwards ${$inView ? 'running' : 'paused'};
`;

export const StyledSection = styled.div`
  margin-bottom: 200px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: ${media.m}) {
    justify-content: space-between;
  }
`;

export const StyledColumn = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :nth-child(3) {
    margin: auto;
  }

  @media (min-width: ${media.s}) {
    align-items: flex-start;
    width: 330px;
    margin-bottom: 0;

    :nth-child(3) {
      width: 660px;
    }
  }

  @media (min-width: ${media.m}) {
    width: 440px;
  }

  @media (min-width: ${media.l}) {
    :nth-child(1) {
      width: 391px;
    }

    :nth-child(2) {
      width: 402px;
    }

    :nth-child(3) {
      margin: 0;
      width: 337px;
    }
  }
`;

export const StyledHeading = styled(Heading)`
  ${dynamicAnimationStyles};
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.grayLight};
  text-align: justify;

  ${dynamicAnimationStyles};

  @media (min-width: ${media.s}) {
    text-align: left;
    padding-right: 10px;
  }

  @media (min-width: ${media.l}) {
    padding-right: 0;
  }
`;

export const StyledButton = styled(Button)`
  font-weight: ${fontWeights.medium};
  font-size: 1.0625rem;
  margin: 30px 0;
`;

export const StyledImage = styled(GatsbyImage)`
  border-radius: 16px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  box-shadow: 10px 10px ${colors.gray};
  margin: auto;
  overflow: hidden;

  @media (min-width: ${media.s}) {
    width: 95%;
  }
`;

export const StyledList = styled.ul`
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  list-style-type: none;
  width: 100%;

  @media (min-width: ${media.l}) {
    margin: 0;
  }
`;
