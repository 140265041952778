import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { StyledContainer } from './styles';
import Label from './Label/Label';

const renderLabel = ({ label, icon, link }) => (
  <Label key={label} text={label} icon={icon} link={link} />
);

const ContactBoxLabels = ({ labels }) => (
  <StyledContainer>{labels.map(renderLabel)}</StyledContainer>
);

ContactBoxLabels.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
};

export const query = graphql`
  fragment ContactBoxLabels on PrismicContactBoxLabels {
    data {
      labels {
        label
        link {
          url
        }
        icon {
          localFile {
            svg {
              originalContent
            }
          }
        }
      }
    }
  }
`;

export default ContactBoxLabels;
