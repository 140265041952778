import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledContainer,
  StyledImage,
  StyledTitle,
  StyledSubTitle,
  StyledDescritpion,
  StyledParagraph,
  StyledPlaceholder,
} from './styles';

import { gatsbyImageProp, richTextProp } from 'utils/types';
import RichText from 'components/_shared/RichText';

const Card = ({
  image,
  description,
  title,
  subtitle,
  alt,
  isBoss,
  className,
}) => (
  <StyledContainer $isBoss={isBoss} className={className}>
    {image ? (
      <StyledImage $isBoss={isBoss} image={image} alt={alt || title} />
    ) : (
      <StyledPlaceholder>{title}</StyledPlaceholder>
    )}
    <StyledDescritpion $isBoss={isBoss}>
      <StyledTitle $isBoss={isBoss}>{title}</StyledTitle>
      <StyledSubTitle>{subtitle}</StyledSubTitle>
      <RichText
        text={description}
        components={{
          paragraph: ({ children }) => (
            <StyledParagraph>{children}</StyledParagraph>
          ),
        }}
      />
    </StyledDescritpion>
  </StyledContainer>
);

Card.propTypes = {
  image: gatsbyImageProp,
  description: richTextProp.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isBoss: PropTypes.bool.isRequired,
  alt: PropTypes.string,
  linkedinLink: PropTypes.string,
  className: PropTypes.string,
};

export default Card;
