import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import {
  StyledSection,
  StyledHeading,
  StyledParagraph,
  StyledMainCard,
  StyledWrapper,
} from './styles';

import Container from 'components/_shared/Container';
import Slider from 'components/_shared/Slider';
import { breakpoints } from 'utils/variables';
import renderCard from 'utils/renderCard';

const sliderConfig = {
  perView: 2,
  type: 'slider',
  gap: 50,
  startAt: 0,
  animationDuration: 700,
  dragThreshold: false,
  rewind: false,
  breakpoints: {
    [breakpoints.l - 1]: {
      perView: 1,
      dragThreshold: true,
    },
  },
};

const inViewConfig = {
  threshold: 0.5,
  triggerOnce: true,
};

const OurTeam = ({ sectionData }) => {
  const { sectionTitle, description, sectionId, workers } = sectionData;
  const mainCard = workers.find(({ isBoss }) => isBoss);
  const workersCards = workers.filter(({ isBoss }) => !isBoss);

  const [mainCardRef, onMainCard] = useInView(inViewConfig);
  const [sliderRef, onSlider] = useInView(inViewConfig);

  return (
    <StyledSection id={sectionId}>
      <Container>
        <StyledHeading type="h2">{sectionTitle}</StyledHeading>
        <StyledParagraph>{description}</StyledParagraph>
        <StyledMainCard $inView={onMainCard} ref={mainCardRef}>
          {renderCard(mainCard)}
        </StyledMainCard>
        <StyledWrapper ref={sliderRef} $inView={onSlider}>
          <Slider config={sliderConfig}>{workersCards.map(renderCard)}</Slider>
        </StyledWrapper>
      </Container>
    </StyledSection>
  );
};

OurTeam.propTypes = {
  sectionData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    workers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};

export default OurTeam;
