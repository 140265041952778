import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import Card from 'components/_shared/Card/Card';

const renderCard = (
  { name, position, isBoss, image, linkedinLink, workerDescription },
  index,
) => {
  const cardImage = getImage(image.localFile);

  return (
    <Card
      key={index}
      image={cardImage}
      alt={image.alt}
      description={workerDescription.richText}
      title={name}
      subtitle={position}
      linkedinLink={linkedinLink.url}
      isBoss={isBoss}
      className={!isBoss ? 'glide__slide' : ''}
    />
  );
};

export default renderCard;
