import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import {
  StyledBoxContainer,
  StyledHeading,
  StyledParagraph,
  StyledSection,
} from './styles';

import Container from 'components/_shared/Container';
import ContactBox from 'components/_shared/ContactBox/ContactBox';

const renderContactBox = ({ primary, labels, id }) => (
  <ContactBox primary={primary} key={id} labels={labels} />
);

const Contact = ({ sectionData }) => {
  const { sectionTitle, sectionId, description, contactBoxes } = sectionData;

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <StyledSection id={sectionId}>
      <Container>
        <StyledHeading type="h2">{sectionTitle}</StyledHeading>
        <StyledParagraph>{description}</StyledParagraph>
        <StyledBoxContainer ref={ref} $inView={inView}>
          {contactBoxes.map(renderContactBox)}
        </StyledBoxContainer>
      </Container>
    </StyledSection>
  );
};

Contact.propTypes = {
  sectionData: PropTypes.shape({
    contactBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    description: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
  }),
};

export default Contact;
