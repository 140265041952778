import styled, { css } from 'styled-components';

import { colors, media } from 'utils/variables';
import Heading from 'components/_shared/Heading/Heading';
import Paragraph from 'components/_shared/Paragraph/Paragraph';
import { fadeIn } from 'utils/keyframes';

const dynamicAnimationStyles = ({ $inView }) => css`
  animation: ${fadeIn} 1s ${$inView ? 'running' : 'paused'};
`;

export const StyledSection = styled.div`
  background-color: ${colors.black};
  margin-bottom: 200px;
  padding-top: 70px;
  padding-bottom: 70px;
`;

export const StyledContent = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  ${dynamicAnimationStyles};
`;

export const StyledHeading = styled(Heading)`
  color: ${colors.grayLight2};
`;

export const StyledDescription = styled(Paragraph)`
  line-height: 44px;
  color: ${colors.grayLight2};
  margin-top: 25px;
  padding-right: 10px;
  padding-left: 10px;

  @media (min-width: ${media.s}) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: ${media.l}) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
