import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import {
  StyledSection,
  StyledContent,
  StyledHeading,
  StyledDescription,
} from './styles';

import Container from 'components/_shared/Container';
import RichText from 'components/_shared/RichText';
import Underline from 'components/_shared/Underline/Underline';
import { richTextProp } from 'utils/types';

const OurHistory = ({ sectionData }) => {
  const { sectionTitle, sectionId, description } = sectionData;

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <StyledSection id={sectionId}>
      <Container>
        <StyledContent $inView={inView} ref={ref}>
          <StyledHeading type="h2">{sectionTitle}</StyledHeading>
          <RichText
            text={description.richText}
            components={{
              paragraph: ({ children }) => (
                <StyledDescription>{children}</StyledDescription>
              ),
              em: ({ children }) => <Underline>{children}</Underline>,
            }}
          />
        </StyledContent>
      </Container>
    </StyledSection>
  );
};

OurHistory.propTypes = {
  sectionData: PropTypes.shape({
    description: PropTypes.shape({
      richText: richTextProp.isRequired,
    }),
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
  }),
};

export default OurHistory;
