import React from 'react';
import PropTypes from 'prop-types';

import { StyledContainer, StyledTitle } from './styles';

import Link from 'components/_shared/Link/Link';

const Label = ({ text, icon, link }) => {
  const { originalContent } = icon.localFile.svg;
  const { url, target } = link;

  return (
    <StyledContainer>
      <div dangerouslySetInnerHTML={{ __html: originalContent }} />
      <StyledTitle as={Link} link={url} target={target} $isHighlighted>
        {text}
      </StyledTitle>
    </StyledContainer>
  );
};

Label.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.shape({
    localFile: PropTypes.shape({
      svg: PropTypes.shape({
        originalContent: PropTypes.string,
      }),
    }),
  }),
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.oneOf([null, '_blank']),
  }),
};

export default Label;
