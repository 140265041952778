import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContactBoxLabels from '../ContactBoxLabels';

import { StyledContainer, StyledTitle, StyledDescription } from './styles';

const ContactBox = ({ primary, labels }) => {
  const { title, description, isDark } = primary;

  return (
    <StyledContainer $isDarkTheme={isDark}>
      <StyledTitle $isDarkTheme={isDark} type="h3">
        {title}
      </StyledTitle>
      <ContactBoxLabels labels={labels} />
      {description && <StyledDescription>{description}</StyledDescription>}
    </StyledContainer>
  );
};

ContactBox.propTypes = {
  primary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isDark: PropTypes.bool.isRequired,
    description: PropTypes.string,
  }),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        localFile: PropTypes.shape({
          svg: PropTypes.shape({
            originalContent: PropTypes.string,
          }),
        }),
      }),
      link: PropTypes.shape({
        url: PropTypes.string,
      }),
      label: PropTypes.string,
    }),
  ),
};

export const query = graphql`
  fragment ContactBox on PrismicContactDataBodyContactBox {
    id
    primary {
      box_description
      isDark: is_dark
      title
    }
    labels: items {
      label
      link {
        url
      }
      icon {
        localFile {
          svg {
            originalContent
          }
        }
      }
    }
  }
`;

export default ContactBox;
