import styled, { css } from 'styled-components';

import { colors, media } from 'utils/variables';
import { fadeIn } from 'utils/keyframes';
import Heading from 'components/_shared/Heading/Heading';
import Paragraph from 'components/_shared/Paragraph/Paragraph';

const dynamicAnimationStyles = ({ $inView }) => css`
  animation: ${fadeIn} 1s ${$inView ? 'running' : 'paused'};
`;

export const StyledSection = styled.div`
  margin-bottom: 200px;
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
  color: ${colors.grayLight};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${media.m}) {
    width: 780px;
  }
`;

export const StyledBoxContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 70px;
  justify-content: center;
  flex-wrap: wrap;

  ${dynamicAnimationStyles};

  @media (min-width: ${media.m}) {
    margin-left: -80px;
    margin-right: -80px;
    justify-content: space-around;
  }
`;
