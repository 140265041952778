import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { colors, fontWeights, media } from 'utils/variables';
import Paragraph from 'components/_shared/Paragraph/Paragraph';

const dynamicContainerStyles = ({ $isBoss }) =>
  $isBoss
  && css`
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${media.s}) {
      max-width: none;
      width: 500px;
    }

    @media (min-width: ${media.m}) {
      width: 800px;
    }

    @media (min-width: ${media.l}) {
      width: 900px;
    }
  `;

const dynamicDescriptionStyles = ({ $isBoss }) => css`
  margin-top: ${$isBoss ? '15px' : '5px'};
`;

const dynamicTitleStyles = ({ $isBoss }) => css`
  font-size: ${$isBoss ? '20px' : '17px'};
`;

const dynamicImageStyles = ({ $isBoss }) =>
  !$isBoss
  && css`
    height: 280px;

    @media (min-width: ${media.s}) {
      height: 350px;
    }

    @media (min-width: ${media.m}) {
      height: 300px;
      max-width: 300px;
    }

    @media (min-width: ${media.l}) {
      max-width: 330px;
      height: 330px;
    }
  `;

const sharedStyles = css`
  border-radius: 19px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);

  ${dynamicImageStyles};
`;

export const StyledContainer = styled.div`
  margin-bottom: 100px;
  text-align: center;

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${media.m}) {
    text-align: left;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${media.l}) {
    text-align: left;
    display: flex;
    align-items: center;
  }

  ${dynamicContainerStyles};
`;

export const StyledPlaceholder = styled.div`
  background-color: ${colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  width: 100%;

  ${sharedStyles}
`;

export const StyledImage = styled(GatsbyImage)`
  ${sharedStyles};
`;

export const StyledDescritpion = styled.div`
  @media (min-width: ${media.m}) {
    padding-left: 30px;
    margin-top: 0;
  }

  ${dynamicDescriptionStyles}
`;

export const StyledTitle = styled.p`
  line-height: 22px;
  font-weight: ${fontWeights.medium};
  color: ${colors.black};
  margin-top: 10px;
  margin-bottom: 10px;

  ${dynamicTitleStyles};
`;

export const StyledSubTitle = styled.div`
  color: ${colors.secondary};
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: 15px;

  :last-child {
    margin: 0;
  }
`;
